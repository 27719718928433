.features {
  margin-top: 10rem;

  .features__intro {
    position: relative;
    margin: 0 auto 10rem;
    text-align: center;
    max-width: 50rem;

    h2, p { margin-bottom: 2rem;}

    &::after {
      @include faded-line;
      top: 120%;
      opacity: 0.75;
    }
  }

  .features__feature {
    display: flex;
    margin-bottom: 5rem;

    .grid__column:first-of-type {
      padding: 3rem 0;

      h3 { padding-bottom: 1rem; }
    }

    .grid__column:last-of-type img {
      margin: 0 0 0 2rem;
      box-shadow: -40px 40px 0px -20px rgba($c-highlight2, 1);
    }

    &:nth-child(even) {
      .grid__column:first-of-type {
        order: 2;
      }

      .grid__column:last-of-type img {
        box-shadow: 40px 40px 0px -20px rgba($c-highlight2, 1);
        margin-left: -2rem; 
      }
    }
   }

  @include media($b-medium) {
    margin-top: 6rem;

    .features__feature {
      .grid__column:first-of-type { order: 1 }
      
      &:nth-child(even) {
        .grid__column:last-of-type img {
          margin: 0;
          box-shadow: none;
        }
      }
  
      .grid__column:last-of-type img {
        margin: 0;
        box-shadow: none;
      }
  
      .features__intro::after {
        display: none;
      }
    }
    
  }
}
