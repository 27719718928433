footer {
  background-color: $c-background;

  .footer__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
    margin: 0 auto;
    padding: 4rem 1rem 2rem;
    max-width: $max-width;
  }

  .footer__column {
    flex: 1;
    color: $c-white;
    margin-bottom: 1rem;
    font-size: 0.95rem;
    min-width: 10rem;
  }

  .footer__column:first-child {
    flex: 1.5;
    display: flex;
    align-items: flex-start;
    align-content: center;

    img {
      width: 2.5rem;
      height: auto;
    }

    p {
      max-width: 13rem;
      padding: 0.25rem 0.5rem;
      font-size: 1.2rem;
      line-height: 1.5;
      font-family: 'PT Sans Caption Bold', Helvetica, sans-serif;
    }
  }

  .footer__title {
    font-size: 1.2rem;
    font-family: 'PT Sans Caption Bold', 'Lucida Sans Unicode', Arial, sans-serif;
    padding-bottom: 0.5rem;
  }

  .footer__column ul {
    img { margin-bottom: 0.2rem; }
  }

  a { color: $c-white; }
  img { width: 1rem }

  .footer__bottom {
    color: $c-white;
    padding: 0 1rem 1rem;
    font-size: 0.8rem;
    margin: 0 auto;
    max-width: $max-width;
  }

  .footer__socials {
    img {
      width: 1.5rem;
      margin-top: 1rem;
      padding-right: 0.5rem; 
    }
  }

  @include media($b-medium-plus) {
    .footer__column:first-child {
      min-width: 100%;
      padding-bottom: 2rem;
    }

    .footer__column {
      min-width: 12rem;
    }
  }
  
  @include media($b-small) {
    .footer__container {
      padding: 3rem 1.5rem;
    }
  }
}