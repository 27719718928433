.testimonial {
  position: relative;
  text-align: center;
  padding: 8rem 0 10rem;
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgb(228, 214, 220) 100%);
  z-index: -1;

  .grid__column {
    margin: 0 auto;
    z-index: 99;
  }

  .testimonial__label {
    color: $c-highlight2;
    font-size: 1rem;
    padding-bottom: 1.5rem;
    font-family: 'Proxima Nova Semibold', Helvetica, sans-serif;
  }

  .testimonial__title {
    line-height: 1.5;
    padding-bottom: 1rem;
    max-width: 40rem;
    margin: 0 auto;
  }

  .testimonial__description { 
    padding-bottom: 2rem;
    max-width: 50rem;
    margin: 0 auto;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 160%;
    background: url('../images/background/abstract-pink.png') no-repeat;
    top: -30rem;
    left: 25%;
    opacity: 0.1;
  }

  @include media(1800px) {
    &::before { left: 10%; }
  }

  @include media($b-large) {
    &::before { left: 2rem; }
  }
}
