.platform {
  .intro {
    margin-top: -10rem;
    padding: 0;
    z-index: 1;

    .compare__image {
      width: 100%;
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.55);
    }
  }

  .roadmap {
    position: relative;
    margin: 0 auto;
    padding: 10rem 0 8rem;
    text-align: center;
    max-width: 50rem;

    h2, p { padding-bottom: 2rem; }

    &::before {
      content: '';
      display: none;
      position: absolute;
      width: 150vw;
      height: 200%;
      background: url('../images/background/abstract-pink.png') no-repeat;
      top: -30rem;
      left: -20rem;
      opacity: 0.1;
      z-index: -1;
    }
  }
  @include media($b-large) {
    .roadmap::before {
      display: block;
    }
  }

  @include media($b-medium) {
    .intro .grid .grid__column + .grid__column {
      display: none;
    }

    .roadmap::before {
      left: -10rem;
    }
  }

  @include media($b-small) {
    top: -10rem;
  }
}