.support-form {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 50vh;
    top: -25vh;
    left: 0;
    z-index: -1;
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgb(228, 214, 220) 100%);
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 3rem;
    background: white;
    text-align: center;
    border-radius: 1rem;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.55);

    h2, p { padding-bottom: 2rem; }

    .subject label { 
      margin-bottom: 0;
    }

    .name {
      display: flex;
      gap: 1rem;
    }

    select {
      appearance: none;
      width: 100%;
      margin: 0;
      padding: 0.5rem 1rem;
      border: 1px solid $c-grayscale4;
      border-radius: 0.5rem;
      font-family: inherit;
      font-size: inherit;
      cursor: inherit;
      line-height: inherit;
    }

    .subject, label {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 1rem;
      font-size: 0.8rem;
    }

    option, input, textarea { 
      width: 100%; 
      padding: 1rem 1rem;
      border: 1px solid $c-grayscale4;
      border-radius: 0.5rem;
      box-shadow: none;
    }

    textarea {
      resize: none;
      height: 15rem;
      margin-bottom: 1rem;
    }

    @include media($b-medium) {
      padding: 3rem 1rem;
    }
  }
}