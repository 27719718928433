.table-of-contents {
  position: sticky;
  top: 5rem;
  padding: 1rem;
  margin: -32rem 1rem 0 0;
  color: $c-black;
  background: $c-white;
  border-radius: 0.5rem;
  border-top: 8px solid $c-highlight3;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.55);

  a {
    color: $c-black;
    font-family: 'Intel Bold', Helvetica, sans-serif;
  }
  
  @include media($b-large) {
    margin: 2rem 0 -2rem;
  }
}
