.widget {
  .widget__icon {
    display: flex;
    position: fixed;
    justify-content: center;
    width: 4rem;
    height: 4rem;
    right: 2rem;
    bottom: 2rem;
    border-radius: 2rem;
    background: linear-gradient(26deg, #14052e 0%, #67173d 100%);
    cursor: pointer;
    z-index: 9;

    img {
      width: 1.75rem;
    }
  }
  
  @include media($b-small) {
    .widget__icon {
      width: 3.5rem;
      height: 3.5rem;
      right: 1rem;
      bottom: 1rem;
    }
  }
}