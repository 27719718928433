nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  max-width: calc($max-width - 1rem);
  border-bottom: 0.5px solid rgba($c-grayscale1, 0.2);
  z-index: 8;

  a, li {  color: $c-grayscale4; }
  .active { color: $c-white; }
  li a:hover, li:hover { color: $c-white; }

  .nav__logo img {
    display: flex;
    max-height: 3rem;
  }

  .nav__links {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    font-family: 'Proxima Nova Regular', Helvetica, sans-serif;

    .nav__link--platform > a,
    .nav__link--resources > a {
      display: none;
    }
  }

  .nav__login .button {
    margin-left: 1.5rem;
  }

  .nav__menu svg {
    display: none;
    color: $c-white;
    padding-top: 0.75rem;
    margin-left: 1rem;
    max-height: 4rem;
  }

  @include media($b-medium-plus) {
    .nav__links {
      display: none;
    }

    .nav__menu svg { 
      display: inline;
    }
  }

  @include media($b-small) {
    .nav__login .button {
      display: none;
    }
  }
}

// Fixed navigation
.headroom-wrapper {
  position: relative;
  z-index: 8;

  .headroom--pinned {
    nav {
      background: linear-gradient(26deg, #14052e 0%, #67173d 100%);
      border-bottom: none;
    }
  }
}
