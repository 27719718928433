@mixin font-face($family, $path, $style: normal, $weight: normal) {
  @font-face {
    font-family: '#{$family}';
    src: url('#{$path}.eot');
    src: url('#{$path}.woff') format('woff');
    font-style: $style;
    text-rendering: optimizeLegibility;
  }
}


// Proxima Nova
@include font-face('Proxima Nova Light', '../fonts/proxima-nova/light/proxima-nova');
@include font-face('Proxima Nova Regular', '../fonts/proxima-nova/regular/proxima-nova');
@include font-face('Proxima Nova Semibold', '../fonts/proxima-nova/semibold/proxima-nova');

// Intel
@include font-face('Intel Regular', '../fonts/intel/regular/intel');
@include font-face('Intel Bold', '../fonts/intel/bold/intel');
@include font-face('Intel Extra Bold', '../fonts/intel/extrabold/intel');

// PT Sans Caption
@include font-face('PT Sans Caption Bold', '../fonts/pt-sans-caption/bold/pt-sans-caption');

body {
  font-size: 1rem;
  line-height: 2.2rem;
  font-family: 'Proxima Nova Light', Helvetica, sans-serif;
  color: $c-text;
}

h1 {
  font-size: 3rem;
  line-height: 5rem;
  font-family: 'Intel Extra Bold', Helvetica, sans-serif;
  color: $c-headings;
}

h2 {
  font-size: 2rem;
  font-family: 'Intel Bold', Helvetica, sans-serif;
  color: $c-headings;
}

h3 {
  font-family: 'Intel Bold', Helvetica, sans-serif;
  color: $c-headings;
}

@include media($b-medium) {
  h1 {
    font-size: 2.5rem;
    line-height: 4rem;
  }

}