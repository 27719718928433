.compare {
  position: relative;
  color: $c-white;
  padding-bottom: 20rem;
  background: $c-background;

  h2 { padding: 8rem 0 3rem; }
  h2, h3 { color: $c-white; }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('../images/background/rounded-top.svg') no-repeat;
    background-size: 100vw;
    bottom: 3rem;
  }
}

.compare__image {
  border-radius: 1rem;
  width: 100%;
  aspect-ratio: 16 / 10;
  object-fit: cover;
}

.compare__content {
  margin: 2rem 0;
  text-align: center;
}

.product .compare__content {
  text-align: center;
}

@include media($b-medium) {
  .compare h2 { padding: 3rem 0; }
}
