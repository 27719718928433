$grid-column-size: 16.667%;

.grid {
  position: relative;
  display: flex;
  gap: 1.5rem;
  margin: 0 auto;
  padding: 0 1rem;
  max-width: $max-width;

  .grid__column {
    padding-bottom: 1.5rem;
  }

  .grid__column--one {
    width: $grid-column-size;
  }

  .grid__column--two {
    width: calc($grid-column-size * 2);
  }

  .grid__column--three {
    width: calc($grid-column-size * 3);
  }

  .grid__column--four {
    width: calc($grid-column-size * 4);
  }

  .grid__column--five {
    width: calc($grid-column-size * 5);
  }

  .grid__column--six {
    width: calc($grid-column-size * 6);
  }

  @include media($b-medium-plus) {
    .grid__column--one {
      width: 25%;
      flex-grow: 1;
    }
  }

  @include media($b-medium) {
    flex-wrap: wrap;

    .grid__column--one,
    .grid__column--two,
    .grid__column--three,
    .grid__column--four,
    .grid__column--five,
    .grid__column--six {
      width: 100%;
    }
  }
} 
