header {
  position: relative;
  color: $c-white;
  padding: 5rem 0 8rem;
  margin-top: -5rem;
  background: linear-gradient(26deg, #14052e 0%, #67173d 100%);
  z-index: -1;

  h1 { color: $c-white; }
  h1 span { color: $c-highlight1; }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('../images/background/header-bottom.svg') no-repeat;
    background-size: 100vw;
    z-index: -1;
  }

  &.header--homepage {
    .header__container {
      margin: 4rem 0 10rem;
      
      h1, p { padding-bottom: 2rem; }
      p { font-size: 1.5rem; }

      .grid__column + .grid__column {
        display: flex;
        justify-content: center;
        align-items: center;

        img { width: 80%; }
      }
    }
  }

  &.header--platform,
  &.header--resources,
  &.header--pricing {
    .header__label {
      color: $c-highlight1;
      text-transform: uppercase;
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', Arial, sans-serif;
      font-weight: 800;
    }

    .header__content {
      position: relative;
      margin: 3rem 0 7rem;
      text-align: left;
      max-width: 50rem;
      
      p { padding-top: 2rem; }
    }
  }

  &.header--static {
    .header__content {
      text-align: left;
      margin-top: 3rem;

      img { width: 2rem; }
      h1 { max-width: 42rem; color: $c-white; }
      p { 
        font-size: 1.2rem;
        line-height: 2.5rem;
        margin-top: 1rem;
      }
    }
  }

  @include media($b-medium-plus) {
    padding-bottom: 4rem;

    &.header--homepage {
      .header__container {
        margin: 4rem 0 -10rem;
        z-index: 9;
      }

      .grid { flex-direction: column; }
      .grid__column { width: 100%; }
    }
  }
}

.platform,
.resources,
.pricing {
  header {
    margin-bottom: -13rem;
  }

  @include media($b-medium-plus) {
    header {
      margin-bottom: -10rem;
    }
  }
}
