.homepage {
  overflow: hidden;

  .showcase {
    margin-top: -12rem;
    z-index: 1;

    img { box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.55); }
  }

  .testimonial { 
    background: none;
    padding: 5rem 0 3rem;
    z-index: -1;

    &::before { height: 300%;}
  }

  .features {
    h2 { padding-bottom: 1rem;}
  }

  .accordion { padding: 0;}

  @include media($b-medium-plus) {
    .header__container { text-align: center; }
    .showcase { display: none; }
  }
}