.accordion {
  text-align: left;
  padding: 0 8rem;

  .accordion__panel {
    position: relative;
    padding: 1.5rem 2rem 1.5rem 0;
    cursor: pointer;
    border-bottom: 1px solid $c-grayscale2;

    .accordion__content {
      display: none;
      text-align: left;
      padding: 1.5rem 0;
    }

    &::after {
      @include arrow-button;
      top: 1.7rem;
      right: 0;
    }
  }

  .accordion__panel.accordion__panel--active {
    .accordion__content { display: block; }
    &::after { transform: rotate(180deg); }
  }
  

  .accordion__panel:last-of-type {
    border-bottom: none;
  }

  @include media ($b-medium-plus) {
    padding: 1rem 0;
  }
}
