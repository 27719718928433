.subnav {
  display: none;
  position: absolute;
  left: 0;
  top: 3rem;
  width: 100%;
  border-top: 1rem solid rgba($c-background, 0);
  z-index: 9;

  .subnav__container {
    display: flex;
    width: 100%;
    height: auto;
    gap: 1rem;
    color: $c-white;
    padding: 1rem 1rem 1.5rem;
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    background: linear-gradient(25deg, #481037 0%, #67173d 100%);
    box-shadow: 0 20px 25px -5px rgba(0,0,0,0.1), 0 8px 10px -6px rgba(0,0,0,0.1);
  }

  .subnav__column { 
    flex: 1; 

    .subnav__title {
      font-size: 1.1rem;
      font-family: 'Proxima Nova Semibold', Helvetica, sans-serif;
      margin: 0 0.5rem;
      min-height: 3rem;
    }

    .subnav__link {
      display: flex;
      align-items: center;
      color: $c-white;
      padding: 0.25rem 0.5rem;
      margin-bottom: 0.5rem;
      border-radius: 0.5rem;
      transition: all .25s ease-in-out;
  
      &:hover {
        background: rgba($c-background, 0.3);
        transition: all .25s ease-in-out;
      }
    }

    .subnav__link img {
      width: 2.5rem;
      height: 2rem;
      padding-right: 0.75rem;
      object-fit: contain;
    }
  
    .subnav__text {
      display: flex;
      flex-direction: column;
    }
  
    p {
      font-family: 'Proxima Nova Semibold', Helvetica, sans-serif;
      margin-bottom: -0.25rem;
    }
  
    p + p { 
      font-family: 'Proxima Nova Light', Helvetica, sans-serif;
      margin: -0.5rem 0 0;
    }
  }

  .subnav__column--dark {
    background: rgba($c-background, 0.3);
    border-radius: 1rem;
    padding: 1.5rem;

    .subnav__title { margin: 0 0 -0.5rem; }
    .subnav__link:hover { background: none; }
  }

  .signup-form {
    h2, .description { display: none; }
    .signup-form__checkbox {
      font-size: 0.8rem;
      line-height: 1.5;
      margin-bottom: 0.75rem;
    }

    .signup-form__input {
      padding: 0.8rem 1rem;
      margin-bottom: 0.75rem;
      width: 100%;
      border: 0;
      border-radius: 0.5rem;
    }
    
    .signup-form__checkbox span { display: none; }

    .signup-form__status {
      font-size: 1rem;
      font-family: 'Proxima Nova Light', Helvetica, sans-serif;
    }

    .signup-form__status--error {
      color: rgb(196, 4, 40);
    }

    .signup-form__status--success {
      color: rgb(23, 214, 23);
    }
  }

  @include media($b-large) {
    p + p { display: none;}
  }
}

.nav__link--platform:hover + .subnav.subnav--platform,
.subnav.subnav--platform:hover {
  display: flex;
  animation: fadeIn 0.2s;

  @include media($b-medium-plus) {
    display: none;
  }
}

.nav__link--resources:hover + .subnav.subnav--resources,
.subnav.subnav--resources:hover {
  display: flex;
  animation: fadeIn 0.2s;

  @include media($b-medium-plus) {
    display: none;
  }
}
