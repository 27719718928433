.static {
  .static__content {
    line-height: 2;

    section {
      position: relative;
      padding: 2.5rem 0;

      h2, h3 { margin-bottom: 1rem; }
      p { line-height: 2.5; }
    }

    section:nth-child(even)::after {
      content: '';
      position: absolute;
      width: calc(100% + 2rem);
      height: 100%;
      top: 0;
      left: -1rem;
      background-color: $c-grayscale1;
      z-index: -1;
    }
  }

  @include media($b-large) {
    section:nth-child(even)::after {
      left: -2rem;
    }

    .grid {
      flex-wrap: wrap;
      .grid__column { width: 100%;}
    }
  }
}
